/**
 * https://gist.github.com/marcandrewb/1d5b4deaac541fc9c61c3f06f9555353
 * // pass in your object structure as array elements
 * const name = getNestedObject(user, ['personalInfo', 'name']);// to access nested array, just pass in array index as an element the path array.
 * const city = getNestedObject(user, ['personalInfo', 'addresses', 0, 'city']);
 * // this will return the city from the first address item.
 * 
 */

export default function GetNestedObject (nestedObj, pathArr) {
    return pathArr.reduce((obj, key) => (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
}
